



















import useMisc from "@/use/misc";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const state = reactive({
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "name", text: root.$tc("layout.misc.name") },
        {
          value: "numberOfParticipants",
          text: root.$tc("layout.misc.numberOfPeople"),
          sortable: false,
          align: "right",
        },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/participant/group/`, {
          params: { sortBy, sortDesc, page, itemsPerPage },
        })
        .then(({ data: { groups, total } }) => {
          state.empty = false;
          state.items = groups.map((group: any) => ({ ...group }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const showParticipants = (id: string) => {
      goBack("panel.event.view.participant.search", { group: id });
    };

    return { state, showParticipants };
  },
});
