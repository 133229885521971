var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',{staticClass:"o-participant-group-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"disable-sort":"","footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"click:row":function (_, ref) {
	var item = ref.item;

	return _vm.showParticipants(item.id);
}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.certificate",fn:function(ref){
var item = ref.item;
return undefined}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }